import React from 'react';
import '../assets/css/loader.css';

const Loader = () => {

  return (
    <div id="cstm_preLoader">
        <div className="cstm_loader">
            <div className="road">
                <div className="line"></div>
                <div className="line center-line"></div>
                <div className="line"></div>
                <div className="icon">
                    <svg width="35" height="35" viewBox="0 0 485 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M179.557 47.1868C176.957 47.8598 171.719 47.0618 168.746 47.4818C209.397 47.7758 276.032 51.1728 316.009 47.4388L305.702 47.3558C301.552 38.3818 295.267 8.43484 291.913 3.98984L290.575 2.22983C287.807 -0.790173 281.477 0.132842 276.87 0.132842C256.29 0.0908424 234.75 0.340842 214.298 0.132842C209.187 0.0468424 203.781 0.340842 198.71 0.132842C191.625 0.299842 191.585 8.39383 190.032 11.2888C188.945 13.3428 190.285 11.2028 189.027 14.5158C187.646 18.1658 187.098 22.3578 185.759 26.2608C185.465 27.1398 185.172 27.8118 185.005 28.8168C184.752 29.6568 179.557 47.1468 179.557 47.1868Z" fill="#FACA0B"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M185.045 28.7764H187.937V34.9404H183.203L185.004 28.8174L185.045 28.7764ZM303.398 40.9814C302.767 39.1364 302.179 37.0404 301.55 34.8574H299.245V40.9814H303.398ZM181.776 39.6804L181.401 40.9814H181.776V39.6804ZM286.885 34.8574H293.047V40.9814H286.885V34.8574ZM274.522 34.8574H280.682V40.9814H274.522V34.8574ZM262.158 34.8574H268.318V40.9814H262.158V34.8574ZM249.793 34.8574H255.957V40.9814H249.793V34.8574ZM237.432 34.8574H243.591V40.9814H237.432V34.8574ZM225.068 34.8574H231.231V40.9814H225.068V34.8574ZM212.705 34.8574H218.866V40.9814H212.705V34.8574ZM200.343 34.8574H206.503V40.9814H200.343V34.8574ZM188.021 34.8574H194.139V40.9814H188.021V34.8574ZM293.047 28.7764H299.203V34.9404H293.047V28.7764ZM280.682 28.7764H286.84V34.9404H280.682V28.7764ZM268.318 28.7764H274.478V34.9404H268.318V28.7764ZM255.957 28.7764H262.115V34.9404H255.957V28.7764ZM243.591 28.7764H249.758V34.9404H243.591V28.7764ZM231.231 28.7764H237.388V34.9404H231.231V28.7764ZM218.866 28.7764H225.025V34.9404H218.866V28.7764ZM206.503 28.7764H212.665V34.9404H206.503V28.7764ZM194.181 28.7764H200.3V34.9404H194.181V28.7764Z" fill="black"/>
                        <path d="M224.566 23.7034H221.465V11.6274H217.483V9.02345H228.546V11.6274H224.566V23.7034ZM240.657 23.7034L239.612 20.1805H234.245L233.199 23.7034H229.846L235.001 8.93945H238.814L244.011 23.7034H240.657ZM238.855 17.5784C237.852 14.4334 237.307 12.6314 237.181 12.2104C237.055 11.7934 236.971 11.4574 236.928 11.2464C236.717 12.0864 236.091 14.2254 235.043 17.5784H238.855ZM260.016 23.7034H256.459L253.062 18.1254L249.671 23.7034H246.314L251.177 16.1104L246.652 9.02345H250.047L253.229 14.3064L256.336 9.02345H259.645L255.075 16.2775L260.016 23.7034ZM264.17 23.7034V9.02345H267.268V23.7034H264.17Z" fill="black"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M33.7545 105.611C24.1575 104.936 9.4465 109.133 4.9225 115.8C-14.2305 143.731 34.3415 139.79 48.0455 140.462C39.1605 147.969 5.4255 183.364 3.3685 191.796C1.1055 201.192 1.1055 276.094 0.894496 292.197C0.561496 315.474 -4.84351 370.373 15.4825 377.838C21.3485 379.979 53.8275 380.19 60.8705 378.635C77.6315 374.942 74.0275 359.633 74.7825 341.477C133.749 337.366 351.171 337.366 409.887 341.477C410.6 358.376 407.242 373.563 421.871 378.174C428.953 380.399 462.9 380.145 469.184 377.838C494.373 368.609 481.299 276.848 481.842 245.562C482.717 190.872 481.002 175.44 436.66 140.462C451.164 139.749 494.749 145.031 480.835 117.478C476.645 109.216 449.993 98.8166 440.142 110.265C420.197 133.625 422.332 108.545 404.144 82.2917C382.937 39.8497 377.617 40.5626 311.107 41.2326C289.23 40.2646 265.549 39.4287 243.633 39.3867C220.917 39.3027 133.328 42.0296 114.427 46.4326C95.9465 46.8116 71.6825 94.6207 63.0895 111.565C49.6385 138.07 52.1105 106.951 33.7545 105.611ZM62.9655 135.933C118.912 136.896 182.53 129.094 241.034 129.055C301.635 128.971 362.192 135.471 421.702 135.933L389.559 73.6107C383.647 63.9637 383.526 60.2327 370.786 58.1347C323.971 50.4587 164.759 51.5086 116.314 57.8406C98.5865 60.1906 98.7125 67.1516 84.8405 91.8546C76.4595 106.741 69.0425 120.624 62.9655 135.933ZM150.888 262.547C150.888 278.986 164.342 292.406 180.772 292.406H304.025C320.455 292.406 333.861 278.986 333.861 262.547V241.955C333.861 225.555 320.412 212.093 304.025 212.093H180.772C164.342 212.093 150.888 225.555 150.888 241.955V262.547ZM112.919 262.631C119.75 259.403 126.708 251.518 129.267 244.973C110.826 234.112 42.4305 229.288 21.4325 233.44C11.9625 289.096 93.6435 271.818 112.919 262.631ZM355.527 244.972C358.046 251.517 364.997 259.402 371.827 262.63C391.112 271.818 472.788 289.095 463.319 233.439C442.364 229.287 373.93 234.111 355.527 244.972Z" fill="#E72C21"/>
                        </svg>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Loader;
